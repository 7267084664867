var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"z1HG2LNVr-J27NDqnl8tR"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://d00263d688d744499af6af1c8e3204d4@o1374399.ingest.sentry.io/6681677',
  enabled: !['development', 'testing'].includes(`${process.env.NEXT_PUBLIC_ENV}`),
  autoSessionTracking: process.env.NEXT_PUBLIC_ENV !== 'development',
  environment: process.env.SENTRY_ENVIRONMENT,

  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],

  // For Sentry.Replay integration
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // For Sentry.BrowserTracing integration
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.3,

  // allow exceptions only for staging || production
  allowUrls: ['https://app.staging.makerconnect.com', 'https://app.makerconnect.com'],
  beforeSend(event, hint) {
    const error = hint.originalException
    if (
      error instanceof Error &&
      error.message?.includes('Failed to fetch') &&
      error.stack?.includes('weglot.min.js')
    ) {
      return null
    }
    // Otherwise, send the event as normal
    return event
  },
})
