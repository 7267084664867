import { DialogProps } from '@mui/material'
import { useCallback, useState } from 'react'

export type ConfirmHandler<T = undefined> = T extends undefined
  ? () => void | Promise<void>
  : (arg: T) => void | Promise<void>

export type CloseHandler = (
  eventOrSubmit?: boolean | MouseEvent | Parameters<NonNullable<DialogProps['onClose']>>['0'],
  // Get the `reason` argument type from the DialogProps's onClose
  reason?: Parameters<NonNullable<DialogProps['onClose']>>['1']
) => void | Promise<void>

export type ConfirmProps<T = undefined> = {
  disableBackdropClose?: boolean
  onConfirm?: ConfirmHandler<T> | (() => unknown)
  onError?: (error: Error) => void | Promise<void>
  onCancel?: () => void | Promise<void>
  onClose?: () => void | Promise<void>
  onOpen?: () => void | Promise<void>
  beginCreate?: () => void
  endCreate?: () => void
}

export type UseConfirmProps<T = undefined> = {
  open: boolean
  loading: boolean
  openHandler: () => void
  confirmHandler: ConfirmHandler<T>
  cancelHandler: CloseHandler
  closeHandler: CloseHandler
  beginCreate?: () => void
  endCreate?: () => void
}

const useConfirm = <T = undefined,>({
  disableBackdropClose = false,
  onConfirm,
  onError,
  onCancel,
  onClose,
  onOpen,
  beginCreate,
  endCreate,
}: ConfirmProps<T> = {}): UseConfirmProps<T> => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const confirmHandler = useCallback<ConfirmHandler<T>>(
    // @ts-expect-error Argument of type '(arg: T) => Promise<void>' is not assignable to parameter of type 'ConfirmHandler<T>'
    async (arg: T) => {
      setOpen(false)
      try {
        if (typeof onConfirm === 'function') {
          setLoading(true)
          if (arg) {
            await onConfirm(arg)
          } else {
            // @ts-expect-error cannot pass empty args to fn
            await onConfirm()
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          onError?.(error)
        } else {
          throw error
        }
      } finally {
        setLoading(false)
      }
    },
    [onConfirm, onError]
  )

  return {
    open,
    loading,
    beginCreate,
    endCreate,
    openHandler: async () => {
      setOpen(true)
      if (typeof onOpen === 'function') {
        onOpen()
      }
    },
    confirmHandler,
    cancelHandler: async () => {
      setOpen(false)
      if (typeof onCancel === 'function') {
        await onCancel()
      }
    },
    closeHandler: async (_, reason) => {
      if (disableBackdropClose && reason === 'backdropClick') {
        return
      }
      setOpen(false)
      if (typeof onClose === 'function') {
        setLoading(true)
        await onClose()
        setLoading(false)
      }
    },
  }
}

export default useConfirm
